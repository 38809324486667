<template>
  <div class="main">
    <div class="invoicet"><Head title="消息通知"></Head></div>
    <div class="Live-empty-list">
      <div class="header">
        <div class="title">{{info.noticeTitle}}</div>
        <div class="date">发布时间：{{info.createTime}}</div>
      </div>
      <div class="cont">
        <div v-html="info.noticeContent"></div>
      </div>
      <div class="btn-wrap">
        <el-button class="w100" type="primary" @click="goBack">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { notice } from '@/api/common'

import Head from './Head'
export default {
  name: 'NoticeDetail',
  data() {
    return {
      info: {},
      noticeId: null
    }
  },
  components: {
    Head
  },
  created() {
    this.noticeId = this.$route.query.noticeId
  },
  mounted() {
    this.getDetail(this.noticeId)
    
  },
  methods: {
    getDetail() {
      notice(this.noticeId).then(res=>{
        if(res.data.code === 0) {
          let _data =  res.data.data;
          _data.noticeContent =  _data.noticeContent.replace(/<img/gi, '<img style="max-width:90%;height:auto"')
          this.info =_data
        //  this.info =  this.info.replace(/img/gi, 'img style="max-width:100%;height:auto"')
        console.log(  this.info,'  this.info   this.info ')
        }else{
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  background: #fff;
  span {
    display: flex;
    padding-bottom: 15px;
  }
  img{
    max-width: 100%;
  }
  .Live-empty-list{
    .header{
      border-bottom: 1px solid #eee;
      padding: 0 10px 15px;
      text-align: center;
      .title{
        font-size: 20px;
        font-weight: 400;
        line-height: 1.5;
        margin-top: 30px;
        margin-bottom: 10px;
      }
      .date{
        text-align: center;
        color: #666;
        font-size: 14px;
        padding-bottom: 10px;
      }
    }
    .cont{
      margin-top: 20px;
      padding:0 20px;
      line-height: 26px;
   
      div{
        text-align: left;
        color:#666;
        text-indent: 2em;
        p{
          text-align: left;
          text-indent: 2em;
          img{
    max-width: 80%;
  }
        }
      }
    }
    .btn-wrap{
      margin-top: 40px;
      padding-bottom: 40px;
    }
  }
}
img{
    max-width: 80% !important;
  }
</style>